<template>
  <div class="sections">
      <div class="sec-title">{{section.checkTitle && !isPark ? section.buildingTitle :section.title}}</div>
      <div class="sec-content">
          <div v-for="item in section.lines" :class="['sec-item', item.fullLine ? 'full-line': '']" :key="item.key">
              <div class="sec-label">{{item.label}}</div>
              
              <div v-if="item.isRange" class="sec-text" >
                <span v-for="itemx in item.list" :key="itemx.key"> {{itemx.label}}<span>{{isEmpty(data[itemx.key]) ? '-' : data[itemx.key]}}</span><span v-if="item.unit">{{item.unit}}</span> </span>

              </div>
              
              <div class="sec-text" v-else>{{isEmpty(data[item.key]) ? '-' : data[item.key]}}<span v-if="item.unit">{{item.unit}}</span></div>
          </div>
      </div>
  </div>
</template>

<script>
export default {
  props: {
    isPark: Boolean,
    data:{
      type: Object,
      default:()=>({}),
      required: true
    },
    section:{
      type: Object,
      default:()=>({}),
      required: true
    },
  },
  methods: {
    isEmpty(val) {
      return val === '' || val === undefined || val === null || val === 'null'
    }
  }


}
</script>

<style lang="scss">
  .sections {
    padding: 20px;
    .sec-title {
      font-size: 20px;
      height: 40px;
      font-weight: bold;
      line-height: 40px;
      // padding-left: 5px;
      position: relative;
      &::before {
        content: '';
        display: inline-block;
        width: 5px;
        height: 15px;
        background-color: #06BDE1;
        border-radius: 5px;
        position: absolute;
        left: -10px;
        top: 50%;
        transform: translateY(-50%);
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
        border-bottom-left-radius: 5px;
      }
    }

    .sec-content {
      width: 100%;
      display: flex;
      flex-wrap: wrap;

      .sec-item {
          width: 50%;
          display: flex;
          
          .sec-label {
              width: 120px;
              padding-right: 10px;
              color: #676767;
              margin-bottom: 8px;
          }
          .sec-text {
              flex: 1;
              word-break: break-all;
              padding-right: 10px;
              margin-bottom: 8px;
          }

          &.full-line {
              width: 100%;
              flex-direction: column;
          }
      }
    }
  }
</style>