<!--  -->
<template>
  <div class="swiper-box">
    <Swiper :options="swiperOption" class="swiperCont swiper-no-swiping" >
      <swiper-slide  class="side" v-for="(vaule,key) in imgs" :key="key" >
          <img class="img" :src="vaule.url" alt="">
      </swiper-slide>       
      <div class="swiper-pagination" slot="pagination"></div>
    </Swiper>
  </div>
</template>

<script>
import 'swiper/css/swiper.css'
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
export default {
  name: 'SwiperImg',
  data () {
    return {
      swiperOption: {
        loop: true,
        autoplay: {
          delay: 3000, // 自动切换的时间间隔，单位ms
          stopOnLastSlide: false,
          disableOnInteraction: false // 用户操作swiper之后，是否禁止autoplay。
        },
        pagination: {
          el: '.swiper-pagination',
        },
        navigation: {
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev",
        },
      }    
    };
  },

  components: {
      Swiper,
      SwiperSlide
  },

  props: {
    imgs:{
      type: Array,
      default:()=>[],
      required: true
    }
  },

  computed: {},

  watch: {},

  methods: {
    onSlideChangeStart (currentPage) {
        // console.log(currentPage);
    },
    changePic(index){
        this.picBig = this.imageUrls[index].url
    }
  }
}

</script>
<style lang='scss'>
.swiper-box {
    .swiperCont{
      height: 420px;
      width: 656px;
      z-index: 300;
    
      .side {
        text-align: center;
        width: 100%;
        height: 100%;
        .img{
            width: 100%;
            height: 100%;
            border: 1px solid white;
            object-fit: cover;
        }
      }
        
    }
}
</style>