<!--  -->
<template>
  <div class="roomDetailsW">
      <div class="pageW">
          <div class="pageW_title"> <span @click="goBack">{{pageTitle}}</span>  <span>></span> <span class="cur-proj">{{isPark ? project.projectName : project.projectName + '-' + building.buildingName}}</span> </div>
          <div class="pageSub">
            <div class="page1">
                <div class="page1_left">
                    <video v-if="isPark" :src="videoUrl" muted autoplay controls loop></video>
                    <SwiperImg v-else-if="!isPark && attachs && attachs.length" class="small-swiper" :imgs="attachs" />
                </div>
                <div class="page1_right">
                    <div class="infoT">
                        <div class="infoT_title">{{project.projectName}}<span v-if="!isPark
                        ">-{{building.buildingName}}</span></div>
                    </div>
                    <div class="infoC">
                        <div class="infoC_title">所属区域:</div>  
                        <div class="infoC_num"><span class="infoC_num_red">{{project.baseName}}</span></div>
                    </div>
                    <div v-if="isPark" class="infoC" >
                        <div class="infoC_title">总建筑面积:</div>  
                        <div class="infoC_num"><span class="infoC_num_red">{{project.totalAreaWan}}</span>万㎡</div>
                    </div>
                    <template v-else>
                        <div class="infoC">
                            <div class="infoC_title">物业类型:</div>  
                            <div class="infoC_num"><span class="infoC_num_red">{{building.buildingType || '-'}}</span></div>
                        </div>
                        <div class="infoC">
                            <div class="infoC_title">租金:</div>  
                            <div class="infoC_num"><span class="infoC_num_red">{{project.rentPrice || '-'}}</span>元/㎡/月</div>
                        </div>
                    </template>
                </div>
            </div>
          </div>


          <div class="page-content">
            <SwiperImg v-if="isPark && attachs && attachs.length" :imgs="attachs" />
            <DetailSection  v-if="isPark" :section="projectInfos" :data="project" />
            <div class="flex-list buildings park" v-else @click="goPark">
                <div class="proj-title">{{project.projectName}}</div>
                <div>地址: {{project.address}}</div>
            </div>
            <div class="sections" v-if="nearbyLabels.length">
                <div class="sec-title">周边配套</div>
                <div class="flex">
                    <div v-for="(label, i) in nearbyLabels" :key="i" class="sec-card">
                        <div class="sec-title sub">{{label}}</div>
                        <div class="sub-info" v-for="item1 in nearby[label]" :key="item1.neId">
                            {{item1.name}}
                        </div>
                    </div>
                </div>
            </div>

            <div class="flex-list buildings" v-if="isPark">
                <span v-for="(item, index) in buildings" :key="item.id" :class="['btn', btnIndex === index ? 'active' : '', item.buildingName.length > 5 ? 'long': item.buildingName.length > 3 ? 'medium' :'']"  @click="buildingBtnChange(index)">{{item.buildingName}}</span>
            </div>
            <SwiperImg v-if="building.attachs && building.attachs.length
            " :imgs="building.attachs" />
            <DetailSection :section="buildingInfos" :data="building" :is-park="isPark" />
            <DetailSection v-if="isWorkshop" :section="workhopInfos" :data="building" />

          </div>
      </div>

  </div>

</template>



<script>
import SwiperImg from '@/components/SwiperImg.vue'
import DetailSection from '@/components/DetailSection.vue'
import { mapState} from 'vuex' 
import {
  getAttachUrl, 
  getProjectDetail,
  getBuildingDetail,
  getNearby
} from "@/api/index";

export default {
    name: '',
    components: {
        SwiperImg,
        DetailSection
    },
    data () {
        return {
            pageTitle:'首页',
            btnIndex: 0,
            nearby: {},
            nearbyLabels: [],
            attachs: [],
            buildings: [],
            building: {},
            project: {},
            isWorkshop: false,
            videoUrl: '',
            isPark: false,
            projectInfos: {
                title: '园区概况',
                lines: [
                    {label: '开发企业', key: 'platformCompany'},
                    {label: '总建筑面积', key: 'totalAreaWan', unit: '万㎡'},
                    {label: '净用地面积', key: 'netArea', unit: '㎡'},
                    {label: '计容建筑面积', key: 'capacityArea', unit: '㎡'},
                    {label: '地上建筑面积', key: 'groundArea', unit: '㎡'},
                    {label: '地下建筑面积', key: 'undergroundArea', unit: '㎡'},
                    {label: '建筑密度', key: 'buildingDensity', unit: '%'},
                    {label: '容积率', key: 'plotRatio'},
                    {label: '绿地率', key: 'greenRatio', unit: '%'},
                    {label: '楼栋数量', key: 'buildingCount', unit: '栋'},
                    {label: '地上车位数', key: 'groundParkCount', unit: '个'},
                    {label: '地下车位数', key: 'undergroundParkCount', unit: '个'},
                    {label: '产业定位', key: 'industryOrientation'},
                    {label: '交付时间', key: 'setupTime', unit: '年'},
                    {label: '园区地址', key: 'address'},
                    {label: '园区介绍', key: 'intro', fullLine: true},
                ]
            },
            buildingInfos: {
                title: '载体指标',
                checkTitle: true,
                buildingTitle: '楼宇详情',
                lines: [
                    {label: '租金', key: 'rentPrice', unit: '元/㎡'},
                    {label: '售价', key: 'totalAreaWan', unit: '元/㎡'},
                    {label: '层数', key: 'industryOrientation', isRange: true, unit: '层', list: [{label: '地面', key: 'groundFloorCount'}, {label: '地下', key: 'undergroundFloorCount'}]},
                    {label: '标准层高', key: 'floorHeight', unit: 'm'},
                    {label: '标准单层面积', key: 'standardArea', unit: '㎡'},
                    {label: '剩余可租赁面积', key: 'rentArea', unit: '㎡'},
                    {label: '运营模式', key: 'operatingMethod'},
                    {label: '结构形式', key: 'buildingStructure'},
                    {label: '建筑总面积', key: 'constructionArea', unit: '㎡'},
                    {label: '建筑高度', key: 'constructionHeight', unit: 'm'},
                    {label: '首层载荷', key: 'firstFloorLoad', unit: 'kg/㎡'},
                    {label: '标准层载荷', key: 'standardFloorLoad', unit: 'kg/㎡'},
                    {label: '电梯数量', isRange: true, unit: '部', list: [{label: '客梯', key: 'customerElevatorCount'}, {label: '货梯', key: 'freightElevatorCount'}]},
                    {label: '交付时间', key: 'operateYear', unit: '年'},
                    {label: '招商联系人', isRange: true, list: [{label: '', key: 'attractContactMan'}, {label: '', key: 'attractContactTel'}]},
                ]
            },
            workhopInfos: {
                title: '厂房指标',
                lines: [
                    {label: '厂房长度', key: 'workshopLength', unit: 'm'},
                    {label: '厂房宽度', key: 'workshopWidth', unit: 'm'},
                    {label: '厂房跨度', key: 'workshopSpan', unit: 'm'},
                    {label: '厂房柱距', key: 'workshopColumn', unit: 'm'},
                    {label: '厂房行吊规格', key: 'workshopCrane', unit: '吨'},
                    {label: '厂房供电功率', key: 'workshopPower', unit: 'kw'}
                ]
            },
        }
    },
    computed: {
        ...mapState(['tabKey']),
    },
    watch: {
        $route: {
            handler() {
                const {id, type} = this.$route.query
                this.isPark = type === 'park'
                this.scrollToTop()
                this.search(id)
                
            },
            deep: true
        }
    },
    mounted() {
        const { id, type } = this.$route.query
        this.isPark = type === 'park'
        this.search(id)
        this.scrollToTop()
    },
    methods: {
        goBack(){
            this.$router.push('/');
        },
        goPark() {
            console.log('goPark');
            this.$router.replace({ query: {id: this.project.id, type: 'park'}});
        },
        search(id) {
            let Api = this.isPark ? getProjectDetail : getBuildingDetail
            Api(id)
                .then(res => {
                    let {attachs, buildings, building, project} = res.data
                    let videoUrl = ''
                    if (attachs && attachs.length) {
                        if (this.isPark) {
                            let videos = attachs.filter(a => a.fileType === '20')
                            videoUrl = videos.length ? getAttachUrl(videos[0].id) : ''
                            attachs = attachs.filter(a => a.fileType === '10')
                        }
                        attachs = attachs.map(attach => {
                            return { ...attach, url: getAttachUrl(attach.id)}
                        })
                    }
                    let isWorkshop = false
                    // 园区
                    if (this.isPark) {
                        building = buildings[0]
                        isWorkshop = building.buildingType && building.buildingType.indexOf('厂房') !== -1
                        // this.setData({
                        //     isWorkshop
                        // })
                        const {latitude, longitude} = project
                        if (latitude && longitude) {
                            this.searchNearbyResource({latitude, longitude})
                        }
                        this.handleBuildingAttachs(building.id).then(res => {
                            building.attachs = res
                            let budingsNew = buildings.map((bu, i) => {
                                if (i === 0) {
                                    bu.attachs = res
                                }
                                return bu
                            })
                            this.building = building
                            this.buildings = budingsNew
                        })
                    }
                    // 楼宇
                    else if (!this.isPark && building) {
                        isWorkshop = building.buildingType && building.buildingType.indexOf('厂房') !== -1
                    }
                    project.totalAreaWan = project.totalArea ? (project.totalArea / 10000).toFixed(2): '-'
                    this.attachs = attachs
                    this.buildings =  buildings || []
                    this.building = building || {}
                    this.project = project
                    this.isWorkshop = isWorkshop
                    this.videoUrl = videoUrl
                })
                .finally(() => {
                    this.isLoading = false
                })
        },
        searchNearbyResource(params) {
            getNearby(params).then(res => {
                let data = res.data
                let list = Object.values(data)
                let resArr = []
                for (let i = 0; i < list.length; i++) {
                    const arr = list[i];
                    if (Array.isArray(arr)) {
                        resArr = resArr.concat(arr)
                    }
                }
                const {projectName, id} = this.project
                resArr.push({...params, name: projectName, id, isCenter: true})
                this.nearby = data,
                this.nearbyLabels = Object.keys(data)
            })
        },
        handleBuildingAttachs(id) {
            return new Promise((resolve, reject) => {
                getBuildingDetail(id).then(res => {
                    let {attachs} = res.data
                    attachs = attachs && attachs.length 
                            ? attachs.map(at => ({...at, url:getAttachUrl(at.id)}))
                            : []
                    resolve(attachs)
                })
            })
        },
        buildingBtnChange(index) {
            let building = this.buildings[index]
            this.isWorkshop = building.buildingType && building.buildingType.indexOf('厂房') !== -1
            this.btnIndex = index
            if (building.attachs && building.attachs.length) {
                this.building = building
            } else {
                this.handleBuildingAttachs(building.id).then(attachs => {
                    building.attachs = attachs
                    this.buildings = this.buildings.map((bu, i) => {
                        if(i === index) {
                            bu.attachs = attachs
                        }
                        return bu
                    })
                    this.building = building
                })
            }
        },
        scrollToTop() {
            document.documentElement.scrollTop = 0;
        }
        
    },

   
}



</script>

<style lang='scss' scoped>
@import "@/assets/css/mixin";
.roomDetailsW{
    // background:$background-color;
    overflow: hidden;
    position: relative;
    .pageW{
         
         &_title{
            font-size: 20px;
            color: #8D8D8D;
            margin: 30px auto 12px auto;
            width: $page-width;
             span:nth-child(1){
                cursor: pointer;
            }
            span:nth-child(2){
                font-size: 14px;
                top: -3px;
                position: relative;
                margin: 0 6px;
            }
            .cur-proj{
                color: #06BDE1;
            }
         }
         .pageSub{
           background: white;
         }
         .page1{
             display: flex;
             justify-content: space-between;
             padding: 20px 0 39px 0;
             width: $page-width;
             margin:auto;
            &_left {
                width: 450px;
                height: 285px;
                background: $background-color;

                video {
                    width: 100%;
                    height: 100%;
                }
                
            }
            &_right{
                color: #333333;
                flex: 1;
                margin-left: 84px;
                >div{
                    margin: 3px 0;
                }
                .infoT{
                    display: flex;
                    justify-content: space-between;
                    align-items: flex-start;
                    padding: 10px 0;
                    box-sizing: border-box;
                    border-bottom: 1px solid #10406B;

                    &_title{
                        font-size: 26px;
                        font-weight: bold;
                        color: #333333;
                        }
                    &_num{
                        font-size: 20px;
                        font-weight: 400;
                        color: #333333;
                        }    
                }

                .infoC{
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    padding: 10px 0;
                    box-sizing: border-box;

                    &_title{
                        font-size: 18px;
                        font-weight: bold;
                        color: #676767;
                        }
                    &_num{
                        font-size: 15px;
                        font-weight: 400;
                        color: #061421;
                            &_black{
                               font-size: 20px;
                               color: #061421;
                            }
                            &_red{
                                font-size: 20px;
                                color: #DF9C41;
                            }
                        }    
                }
                .infoR{
                    display: flex;
                    justify-content: flex-end;
                    align-items: center;
                    font-size: 16px;
                    font-weight: 400;
                    color: #79AAD5;
                    &_img{
                        width: 16px;
                        height: 16px;
                        }
                    
                }
            }
         }


         .page-content {
            color: #333;
            max-width: 1200px;
            margin: 0 auto;
            
            .flex {
                width: 100%;
                display: flex;
                flex-wrap: wrap;
            }
            .sec-card {
                width: 50%;
                padding-left: 20px;
                margin: 10px 0;
                box-sizing: border-box;
                font-size: 16px;
                .sec-title.sub {
                    font-size: 16px;
                    // font-weight: bold;
                    &::before {
                        background-color: #75daee;
                    }
                }
                .sub-info {
                    height: 30px;
                }
            }

            .flex-list.buildings {
                background: $background-color;
                padding: 20px;
                border-radius: 5px;
                margin-bottom: 20px;

                &.park {
                    cursor: pointer;
                    background: rgba($color: #06bde1, $alpha: 0.05);
                    margin-top: 10px;
                }
            }

            .proj-title {
                font-weight: bold;
                font-size: 20px;
                margin-bottom: 8px;
            }

            .flex-list .btn {
                display: inline-block;
                cursor: pointer;
                padding: 5px 8px;
                min-width: 50px;
                margin-right: 10px;
                border-radius: 30px;
                color: #7C7E94;
                text-align: center;
                font-size: 16px;
                flex-shrink: 0;
            }
            .flex-list .btn.active {
                color: #fff;
                background: linear-gradient(90deg, #25A9E2 0%, #22F5EE 100%);
            }
            .building-name-place-holder {
                height: 1px;
                width: 100px;
            }
            .flex-list .btn.long + .building-name-place-holder{
                width: 200px;
            }
         }

    }
    

}
</style>

<style>
.swiper-box.small-swiper .swiperCont {
    width: 450px;
    height: 320px;
}
</style>